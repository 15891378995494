<template>
  <div style="background: #f9f9f9; border-radius: 10px">
    <div class="wrapper" id="canvasImg" ref="print">
      <!-- <el-button type="primary" class="deriveWord noprint" @click="printFn">一键导出</el-button> -->
      <div class="inner fateher">
        <!-- 基本信息 -->
        <div class="page page1" id="fateher">
          <!-- 个人简历标题 -->
          <div class="son">
            <p class="big-title">个人简历</p>
            <div class="bold-line"></div>
          </div>

          <!-- 基本信息 -->
          <div class="small-title son">
            <p class="title-p">基本信息</p>
            <div class="red-border"></div>
            <!-- 内容 -->
            <div class="content-message">
              <div class="content-s l">
                <div class="text" v-if="obj && obj.name">
                  <p class="w-60">姓名：{{ obj.name }}</p>
                </div>
                <div class="text" v-if="obj && obj.gender">
                  <p class="w-60">性别：{{ obj.gender == 1 ? '男' : '女' }}</p>
                </div>
                <div class="text" v-if="obj && obj.age">
                  <p class="w-60">年龄：{{ obj.age }}</p>
                </div>
                <div class="text" v-if="obj && obj.home_phone_number">
                  <p class="w-60">固定电话：{{ obj.home_phone_number }}</p>
                </div>
                <div class="text" v-if="obj && obj.QQ">
                  <p class="w-60">QQ：{{ obj.QQ }}</p>
                </div>
                <div class="text" v-if="obj && obj.height">
                  <p class="w-60">身高 ：{{ obj.height }}</p>
                </div>
                <div class="text" v-if="obj && obj.ethnic">
                  <p class="w-60">民族：{{ obj.ethnic }}</p>
                </div>
                <div class="text" v-if="obj && obj.current_location">
                  <p class="w-60">现居城市：{{ obj.current_location }}</p>
                </div>
                <div class="text" v-if="obj && obj.zipcode">
                  <p class="w-60">邮编：{{ obj.zipcode }}</p>
                </div>
                <div class="text" v-if="obj && obj.num_work_experience">
                  <p class="w-60">工作年限：{{ obj.num_work_experience }} 年</p>
                  <p></p>
                </div>
                <div class="text" v-if="obj && obj.identity">
                  <p class="w-60">
                    身份：{{
                      obj.identity == 1 ? '在校生' : obj.identity == 2 ? '应届生' : '社会求职'
                    }}
                  </p>
                </div>
                <div class="text" v-if="obj && obj.date_of_birth">
                  <p class="w-60">出生年月：{{ obj.date_of_birth }}</p>
                </div>
                <div class="text" v-if="obj && obj.phone_number">
                  <p class="w-60">手机号：{{ obj.phone_number }}</p>
                </div>
                <div class="text" v-if="obj && obj.wechat">
                  <p class="w-60">微信号：{{ obj.wechat }}</p>
                </div>
                <div class="text" v-if="obj && obj.email">
                  <p class="w-60">邮箱：{{ obj.email }}</p>
                </div>
                <div class="text" v-if="obj && obj.weight">
                  <p class="w-60">体重：{{ obj.weight }}kg</p>
                </div>
                <div class="text" v-if="obj && obj.birthplace">
                  <p class="w-60">籍贯：{{ obj.birthplace }}</p>
                  <p></p>
                </div>
                <div class="text" v-if="obj && obj.detailed_location">
                  <p class="w-60">详细地址：{{ obj.detailed_location }}</p>
                  <p></p>
                </div>
                <div class="text" v-if="obj && obj.marital_status && obj.marital_status >= 0">
                  <p class="w-60">
                    婚姻状况 ：{{
                      obj.marital_status == 0
                        ? '未婚'
                        : obj.marital_status == 1
                        ? '已婚'
                        : obj.marital_status == 3
                        ? '离异'
                        : '再婚'
                    }}
                  </p>
                  <p></p>
                </div>
                <div class="text" v-if="obj && obj.marital_status && obj.marital_status.length > 1">
                  <p class="w-60">婚姻状况 ：{{ obj.marital_status }}</p>
                  <p></p>
                </div>
                <!-- <div class="content-s r"> -->

                <!-- </div> -->
              </div>
              <!-- 照片 -->
              <!-- <div class="my-photo">
                <img src="@/assets/myphoto.jpg" alt="" />
              </div> -->
              <!-- 照片 -->
            </div>
          </div>
          <!-- 基本信息 -->

          <!-- 求职意向 -->
          <div class="small-title son" v-if="obj && obj.intention && obj.intention.length">
            <p class="title-p">求职意向</p>
            <div class="red-border"></div>

            <div v-if="obj && obj.intention">
              <div v-for="(item, index) in obj.intention" :key="index">
                <!-- 内容 -->
                <div class="content font-11 toapply">
                  <div class="content-s l">
                    <div class="text" v-if="item.nature_name">
                      <p class="w-60">工作性质：{{ item.nature_name }}</p>
                    </div>
                    <div class="text" v-if="item.city">
                      <p class="w-60">工作地区：{{ item.cantonal }}</p>
                    </div>
                    <div class="text" v-if="item.trade_name">
                      <p class="w-60">期望行业：{{ item.trade_name }}</p>
                    </div>
                  </div>
                  <div class="content-s r">
                    <div class="text" v-if="item.category_label">
                      <p class="w-60">期望职位：{{ item.category_label }}</p>
                    </div>
                    <div class="text" v-if="item.minwage_name && item.maxwage_name">
                      <p class="w-60" v-if="item.minwage_name !== '面议'">
                        期望薪资：{{ item.minwage_name }}-{{ item.maxwage_name }}
                      </p>
                      <p class="w-60" v-else>期望薪资：{{ item.minwage_name }}</p>
                    </div>
                    <div class="text" v-if="item.intentionName">
                      <p class="w-60">意向岗位：{{ item.intentionName }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 求职意向 -->

          <!-- 教育经历 -->
          <div
            class="small-title son"
            v-if="obj && obj.education_experience && obj.education_experience.length"
          >
            <p class="title-p">教育经历</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div v-if="obj && obj.education_experience">
              <div
                class="font-11 toapply"
                v-for="(item, index) in obj.education_experience"
                :key="index"
              >
                <div class="content font-11">
                  <div class="content-s l">
                    <div class="text" v-if="item.school_name">
                      <p class="w-60">学校名称：{{ item.school_name }}</p>
                    </div>
                    <div class="text" v-if="item.start_time && item.end_time">
                      <p class="w-60">
                        在校时间：{{ item.start_time_startDate }}-{{ item.start_time_endDate }}
                      </p>
                    </div>
                  </div>
                  <div class="content-s r">
                    <div class="text" v-if="item.degree">
                      <p class="w-60">学历：{{ item.degree }}</p>
                    </div>
                    <div class="text" v-if="item.major">
                      <p class="w-60">专业名称：{{ item.major }}</p>
                    </div>
                  </div>
                </div>
                <div class="content font-11" v-if="item.description">
                  <div class="text">
                    <div class="arrow-wrapper">
                      <p>
                        <span class="arrow-icon iconfont icon-paixujiantou"></span>
                        <span class="arrow-text">
                          {{ item.description }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 教育经历 -->

          <!-- 工作经历 -->
          <div
            class="small-title son"
            v-if="obj && obj.work_experience && obj.work_experience.length"
          >
            <p class="title-p son">工作经历</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div class="content font-11 son">
              <div class="text son" v-if="obj && obj.work_experience">
                <div
                  class="text-1 toapply son"
                  v-for="(item, index) in obj.work_experience"
                  :key="index"
                >
                  <div class="toapplyTitle son">
                    <span class="mr-56 son" v-if="item.job_title">
                      职位名称: {{ item.job_title }}
                    </span>
                    <span class="mr-56 son" v-if="item.start_time && item.end_time">
                      工作时间： {{ item.start_time_startDate }}-{{ item.start_time_endDate }}
                    </span>
                    <span class="mr-56 son" v-if="item.company_name">
                      公司名称：{{ item.company_name }}
                    </span>
                  </div>
                  <div class="arrow-wrapper son" v-if="item.description">
                    <p v-for="(text, index) in item.work_experienceList" :key="index" class="son">
                      <span class="arrow-icon iconfont icon-paixujiantou son"></span>
                      <span class="arrow-text son">{{ text }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 工作经历 -->

          <!-- 项目经验  -->
          <div
            class="small-title son"
            v-if="obj && obj.project_experience && obj.project_experience.length"
          >
            <p class="title-p son">项目经历</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div v-if="obj && obj.project_experience" class="son">
              <div
                class="content font-11 content-6 son"
                v-for="(item, index) in obj.project_experience"
                :key="index"
              >
                <!-- 项目一 -->
                <div class="text son">
                  <span class="mr-30 project-title son" v-if="item.project_name">
                    项目名称：{{ item.project_name }}
                  </span>
                  <span class="fr son" v-if="item.job_title">职位名称：{{ item.job_title }}</span>
                </div>
                <div class="arrow-1 son">
                  <p v-if="item.start_time && item.end_time">
                    时间： {{ item.start_time_startDate }}-{{ item.start_time_endDate }}
                  </p>
                  <div v-if="item.description" class="son">
                    <p>经历描述：</p>
                    <div class="ml-40 son">
                      <div class="arrow-wrapper son">
                        <p>
                          <span class="arrow-text son">{{ item.description }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="h-20"></div>
              </div>
            </div>
          </div>
          <!-- 项目经验  -->

          <!-- 培训经历 -->
          <div
            class="small-title son"
            v-if="obj && obj.training_experience && obj.training_experience.length"
          >
            <p class="title-p">培训经历</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div class="content font-11">
              <div class="text" v-if="obj && obj.training_experience">
                <div
                  class="text-1 toapply"
                  v-for="(item, index) in obj.training_experience"
                  :key="index"
                >
                  <div class="toapplyTitle">
                    <span class="mr-56" v-if="item.subject">培训课程: {{ item.subject }}</span>
                    <span class="mr-56" v-if="item.start_time && item.end_time">
                      培训时间：{{ item.start_time_startDate }}-{{ item.start_time_endDate }}
                    </span>
                    <span class="mr-56" v-if="item.organization_name">
                      培训机构: {{ item.organization_name }}
                    </span>
                  </div>

                  <div class="arrow-wrapper" v-if="item.description">
                    <p>
                      <span class="arrow-icon iconfont icon-paixujiantou"></span>
                      <span class="arrow-text">
                        {{ item.description }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 培训经历 -->

          <!-- 技能 -->
          <div class="small-title son" v-if="obj && obj.skills_full && obj.skills_full.length">
            <p class="title-p">相关技能</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div class="content font-11">
              <div class="text" v-if="obj && obj.skills_full">
                <div class="text-1 toapply" v-for="(item, index) in obj.skills_full" :key="index">
                  <div class="toapplyTitle">
                    <span class="mr-56" v-if="item.name">技能名称: {{ item.name }}</span>
                    <span class="mr-56" v-if="item.time">掌握时长： {{ item.time }}/月</span>
                    <span class="mr-56" v-if="item.level">掌握程度：{{ item.level }}</span>
                  </div>
                  <div class="arrow-wrapper" v-if="item.description">
                    <p>
                      <span class="arrow-icon iconfont icon-paixujiantou"></span>
                      <span class="arrow-text">
                        {{ item.description }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 技能 -->

          <!-- 荣誉奖项 -->
          <div class="small-title son" v-if="obj && obj.awards_full && obj.awards_full.length">
            <p class="title-p">荣誉奖项</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div v-if="obj && obj.awards_full">
              <div class="font-11" v-for="(item, index) in obj.awards_full" :key="index">
                <div class="content font-11">
                  <div class="content-s l">
                    <div class="text" v-if="item.name">
                      <p class="w-60">奖项名称：{{ item.name }}</p>
                    </div>
                  </div>
                  <div class="content-s r">
                    <div class="text" v-if="item.time">
                      <p class="w-60">获得时间：{{ item.time }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 荣誉奖项 -->

          <!-- 自我评价 -->
          <div class="small-title son" v-if="obj && obj.self_evaluation">
            <p class="title-p">自我评价</p>
            <div class="red-border"></div>

            <!-- 内容 -->
            <div class="content font-11">
              <div class="text">
                <div class="arrow-wrapper">
                  <p>
                    <span class="arrow-icon iconfont icon-paixujiantou"></span>
                    <span class="arrow-text">
                      {{ obj.self_evaluation }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 自我评价 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PdfLoader from '@/utils/htmlpdf.js'
import { scImg } from '@/utils/scImg'
import { upyunUrl } from '@/api/config'

export default {
  data() {
    return {
      name: '',
      obj: null,
      upyunData: { policy: '', signature: '' }
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  created() {
    if (this.currentResumeInfo) this.obj = this.currentResumeInfo
  },
  methods: {
    ...mapActions(['getUpYunSign', 'resumeFileUpyunImg', 'resumeFileUpyunPdf']),
    async printFn() {
      await this.getUpYunSignFn()
      this.name = this.obj.resume_name
      if (this.name === null) {
        //拼接导出文件名字
        this.name = `${this.obj.name || 'xxx'}-${
          this.obj.intention.length ? this.obj.intention[0].intentionName : '岗位'
        }-${this.obj.num_work_experience || ''}年/${
          this.obj.education_experience.length ? this.obj.education_experience[0].end_time_year : ''
        }届毕业`
      }
      // son防止文字截断的class名  此方法作用是转成base64
      const pdf = new PdfLoader(document.getElementById('fateher'), this.name, 'son')
      pdf.outPutPdfFn('customFileName').then(base64PDF => {
        // 处理 base64PDF 数据
        this.UploadPdf(base64PDF)
      })

      //  直接pdf导出的方法 需要把htmlpdf.js的注释方法也要打开
      //  const pdf = document.getElementById('canvasImg') // 需要导出部分页面的id名
      //  this.pdfDownLoader = new PdfLoader(pdf, this.name, 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      //  this.pdfDownLoader.outPutPdfFn(this.name)
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.name
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyunPdf(data)
      const oJson = {
        id: this.currentResumeInfo.id,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      // oJson就是简历的详情和在线链接  方便父组件调用
      this.$emit('dialogVisibleFn', oJson)
    },
    // 打印
    handlePrint() {
      this.$print(this.$refs.print)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 页面转图方法
    async scImg() {
      const res = await scImg()
      return res
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.obj = obj
    }
  }
}
</script>

<style scoped lang="stylus">
.appWrapper
  background-color #e2e8ca
  border-radius 10px
.appWrapper .innerWrapper
  max-width 700px
  margin 0 auto
  background #fff
  padding 10px
nav
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align cente
  color #2c3e50
  padding 30px
nav a
  font-weight bold
  color #2c3e50
nav a.router-link-exact-active
  color #42b983
@media print
  /* 不打印区 */
  .noprint
    display none
  /* 打印设置 */
  .page2
    page-break-before always
.wrapper
  background #f9f9f9
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  border-radius 10px
.wrapper .inner
  text-align left
  border-radius 10px
.wrapper .inner .page
  position relative
  width 828px
  margin 0 auto
  background #ffffff
  padding 0 0 20px 0
  border-radius 10px
.wrapper .inner .page .font-11
  font-size 15px
  color #686868
  line-height 20px
.wrapper .inner .page .big-title
  padding-top 12px
  margin 0 0 12px 40px
  font-size 28px
  font-weight bold
  color #6495ed
.wrapper .inner .page .bold-line
  height 15px
  width 100%
  background #6495ed
.my-photo
  flex 1
.my-photo img
  width 111px
  height 111px
.wrapper .inner .page .small-title
  padding 12px 40px 0px 40px
.wrapper .inner .page .small-title img
  margin-left 37px
  width 121px
  height auto
  vertical-align middle
.wrapper .inner .page .small-title .title-p
  display inline-block
  vertical-align middle
  font-size 17px
  color #6495ed
  margin-left 5px
  font-weight bold
.wrapper .inner .page .small-title .red-border
  height 1px
  margin 5px 0
  border-bottom 1px solid #6495ed
.wrapper .inner .page .small-title .content
  display flex
.toapply
  margin-bottom 10px
.toapplyTitle
  display flex
  justify-content space-between
.toapplyMr-56
  display flex
.mr-56
  width 30%
.wrapper .inner .page .small-title .content .mr-56
  margin-right 0px
  flex 1
.wrapper .inner .page .small-title .content .mr-30
  margin-right 30px
.wrapper .inner .page .small-title .content .ml-40
  margin-left 40px
.wrapper .inner .page .small-title .content .fr
  float right
.wrapper .inner .page .small-title .content .h-20
  height 20px
.wrapper .inner .page .small-title .content .project-title
  font-weight bold
  padding 2px 3px
  background-color #f6f6f6
  border-radius 5px
  border 1px solid #ebebeb
.wrapper .inner .page .small-title .content .text
  width 100%
.wrapper .inner .page .small-title .content .text .text-2
  margin-top 20px
.wrapper .inner .page .small-title .content.content-6
  display block
.wrapper .inner .page .small-title .content.content-4, .wrapper .inner .page .small-title .content.content-7
  display block
.wrapper .inner .page .small-title .content.content-4 img, .wrapper .inner .page .small-title .content.content-7 img
  width 9px
  height auto
  margin-right 10px
.wrapper .inner .page .small-title .content .content-s
  width 50%
.wrapper .inner .page .small-title .content .content-s .text p.w-60
  white-space nowrap
  height 25px
  width 100%
.wrapper .inner .page .small-title .content .content-s .text p.w-60::after
  content ''
  display inline-block
  width 100%
.wrapper .inner .page .small-title .content .arrow-1 > p
  display flex
.wrapper .inner .page .small-title .content .arrow-1 > p::before
  display block
  width 19px
  height 20px
  background url('~@/assets/arrow-1.png') no-repeat
  background-size 9px auto
  background-position left center
.wrapper .inner .page .small-title .content .arrow-wrapper > p .arrow-icon
  display block
  width 10px
  height 20px
.arrow-wrapper
  margin-top 1px
.wrapper .inner .page .small-title .content .arrow-wrapper > p .arrow-text
  padding-left 26px
  display block
  margin-top -20px
.deriveWord
  position fixed
  top 50%
  right 0
.content-message
  font-size 15px
  color #686868
  line-height 25px
  .content-s
    display flex
    flex-wrap wrap
    .text
      width 50%
</style>
