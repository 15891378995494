import store from '../store'
import html2canvas from 'html2canvas'
let upyunData = { policy: '', signature: '' }

// 获取又拍云签名
async function getUpYunSignFn() {
  upyunData = await store.dispatch('getUpYunSign')
}

//将页面转成base64的格式 图
export function scImg() {
  return new Promise(async (resolve, reject) => {
    try {
      const canvas = await html2canvas(document.querySelector('#canvasImg'), {
        scale: 2,
        dpi: 1000,
        allowTaint: false,
        useCORS: true,
        borderRadis: 10,
        backgroundColor: '#fff'
      })

      await getUpYunSignFn()
      let imgUri = canvas.toDataURL('image/png')
      let file = await dataURLtoFile(imgUri, 'img.png')
      const res = await resumeFileUpyunFn(file)

      resolve(res) // 在所有异步操作完成后才 resolve
    } catch (error) {
      reject(error)
    }
  })
}

// 上传简历 获取图片路径
async function resumeFileUpyunFn(file) {
  const data = new FormData()
  data.append('file', file)
  data.append('policy', upyunData.policy)
  data.append('signature', upyunData.signature)
  const res = await store.dispatch('resumeFileUpyunImg', data)
  return res
}
//将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
function dataURLtoFile(dataurl, filename = '') {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
